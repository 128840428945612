.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* .Page {
  background: linear-gradient(to bottom, #ffffff 0%, #01a8f7 100%);
} */
.Page {
  background: #faf9f6;
  /* background: #5c5c5c; */
}

::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #24242475;
  opacity: 1; /* Firefox */
}

:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: #24242475;
}

::-ms-input-placeholder { /* Microsoft Edge */
  color: #24242475;
}

/* .Message {
  cursor: pointer;
  background-color: black
} */

/* .cs-message__content {
  padding: 0%;
}

.cs-message--outgoing {
  padding: 0%;
} */

@property --scrollbarThumbColour {
  syntax: "<color>";
  inherits: true;
  initial-value: #0000;
}

.CustomScrollbar {
  overflow-x: hidden;
  overflow-y: auto;
  transition: --scrollbarThumbColour 0.4s;
}
/* Show the scrollbar thumb when the main element is hovered on */
.CustomScrollbar:hover {
  --scrollbarThumbColour: #91bdf2;
}
.CustomScrollbar::-webkit-scrollbar  {
  width: 10px;
}
.CustomScrollbar::-webkit-scrollbar-track {
  background: #0000;
}

.CustomScrollbar::-webkit-scrollbar-thumb {
  background-color: var(--scrollbarThumbColour);
  border-radius: 5px;
  border: 2px solid transparent;
  background-clip: padding-box;
  
}

.CustomScrollbar::-webkit-scrollbar-thumb:hover {
  background: #87b5ed;
  border: 2px solid transparent;
  background-clip: padding-box;
}

.HoverBackground {
  cursor: pointer;
  background-color: var(--backgroundColour, #3085c7);
  transition: background-color 0.2s ease;
}

.HoverBackground:hover {
  background-color: var(--hoverBackgroundColour, #8fc7ff);
}