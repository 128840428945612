@keyframes spinner {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  .spinner-container {
    text-align: center;
    color: #1424B2;
    /* background: linear-gradient(to bottom, #ffffff 0%, #01a8f7 100%); */
  }

  .loading-spinner {
    width: 50px;
    height: 50px;
    border: 10px solid #f3f3f3;
    border-top: 10px solid #01a8f7;
    border-radius: 50%;
    animation: spinner 1.5s linear infinite;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
  }